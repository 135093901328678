import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import {Row,Col,Button,} from "reactstrap";
import { default as ReactSelect } from "react-select";
import {getDcForTally,getDcForTallyCSV} from "../../services/ReportServices";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {  DatePicker } from 'antd';
import { getAllPlants} from '../../services/PlantServices';
import moment  from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';

const {RangePicker} = DatePicker;
function DcForTally() 
{
    const InitialData = {
        records: []
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [plants, setPlants] = React.useState([]);
    const [input, setInput] = useState({});
    const [taxlist, setTaxlist] = React.useState([]);

    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        }
    };

    React.useEffect(() => {
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                const plants = plantList.plant_list.map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);

    const Home = () => {
        navigate('/Home')
    }

    const columns = useMemo(
        () => {
            const baseColumns =[
            {
                fieldName: "delivery_challan_date",
                headerName: 'Date'
            },
            {
                fieldName: 'delivery_challan_no', 
                headerName: 'Voucher No.'
            },
            {
                fieldName: 'voucher_type', 
                headerName: 'Voucher Type'
            },
            {
                fieldName: 'customer_name', 
                headerName: 'Buyer (Bill to)'
            },
            {
                fieldName: 'customer_address_1', 
                headerName: 'Buyer (Bill to) Address 1'
            },
            {
                fieldName: 'customer_address_2', 
                headerName: 'Buyer (Bill to) Address 2'
            },
            {
                fieldName: 'customer_address_3', 
                headerName: 'Buyer (Bill to) Address 3'
            },
            {
                fieldName: 'customer_pincode', 
                headerName: 'Buyer (Bill to) Pincode'
            },
            {
                fieldName: 'consignee_name', 
                headerName: 'Consignee (Ship to)'
            },
            {
                fieldName: 'consignee_address_1', 
                headerName: 'Consignee (Bill to) Address 1'
            },
            {
                fieldName: 'consignee_address_2', 
                headerName: 'Consignee (Bill to) Address 2'
            },
            {
                fieldName: 'consignee_address_3', 
                headerName: 'Consignee (Bill to) Address 3'
            },
            {
                fieldName: 'consignee_pincode', 
                headerName: 'Consignee (Bill to) Pincode'
            },
            {
                fieldName: "purchase_order_no",
                headerName: 'Voucher Ref. No.'
            },
            {
                fieldName: "customer_gst_no",
                headerName: 'GSTIN/UIN'
            },
            {
                fieldName: "customer_pan_no",
                headerName: 'PAN No.'
            },
            {
                fieldName: "user_remarks",
                headerName: 'Narration'
            },
            {
                fieldName: "sales_order_no",
                headerName: 'Order No'
            },
            {
                fieldName: "sales_order_date",
                headerName: 'Order Date'
            },
            {
                fieldName: 'pay_terms', 
                headerName: 'Terms of Payment'
            },
            {
                fieldName: 'vehicle_no', 
                headerName: 'Vehicle No'
            },
            {
                fieldName: 'delivery_mode', 
                headerName: 'Terms of Delivery'
            },
            {
                fieldName: 'dispatch_through', 
                headerName: 'Dispatch Through'
            },
            {
                fieldName: "project_name",
                headerName: 'Destination'
            },
            {
                fieldName: 'dc_grade', 
                headerName: 'Description of Goods'
            },
            {
                fieldName: 'dc_qty', 
                headerName: 'Quantity'
            },
            {
                fieldName: 'basic_rate', 
                headerName: 'Basic Rate'
            },
            {
                fieldName: 'amount', 
                headerName: 'Amount'
            },
            {
                fieldName: 'gross_total', 
                headerName: 'Gross Total'
            },
            {
                fieldName: 'rounded_off', 
                headerName: 'Rounded Off'
            },
        ];
        console.log(JSON.stringify(data.records)+"data");
        // console.log(JSON.stringify(taxlist)+"taxlist");
        const dynamicColumns = [];
        for (let i = 0; i < taxlist.length; i++) {

            dynamicColumns.push({
                fieldName: taxlist[i].includes(".")?taxlist[i].replace(/[@.]/g, '_'):taxlist[i],
                headerName: taxlist[i]
            });
        }
        console.log(JSON.stringify(dynamicColumns)+"dynamicColumns");
        // Create columns dynamically for tax_list elements
        // const dynamicColumns = data.records.reduce((acc, dc) => {
        // dc.tax_list.forEach(tax => {
        //         if (!acc.some(column => column.fieldName === tax)) {
        //             acc.push({ fieldName: tax, headerName: tax });
        //         }
        //     });
            // return acc;
        // }, []);

        return [...baseColumns, ...dynamicColumns];
    },[data.records]);
   
    const handleCSVExport = (event) => {
    };

    // const handlePDFExport = (event) => {
    // };
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        // console.log(name+value+"name and value")
        setInput(values => ({...values, [name]: event.target.value}))
    }

    const handleDownloadCsv = () => {
        // setDownloadOption('csv')
        const inputs = {
            plant_id:input.plant_id,
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY')
          }
       
        setData({
            records: []
        });
        if ((inputs.plant_id) && (inputs.from_date) && (inputs.upto_date))
        {
            getDcForTallyCSV(cookies, inputs)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Sheet1.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("plant,from_date,upto_date must not be empty.", "", "info");  
        }
    };
    const handleSubmit=(event)=> 
    {
        event.preventDefault();
        const inputs = {
            plant_id:input.plant_id,
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY')
          }
        if ((inputs.plant_id) &&
        (inputs.from_date) && (inputs.upto_date))
        {
            getDcForTally(cookies, inputs)
            .then(response => {
                const tableData = response.delivery_challan_list
                .map(dc => 
                    {
                        const rowData ={
                    id: dc.id,
                    dispatch_through:dc.dispatch_through,
                    delivery_challan_date: dc.date,
                    delivery_challan_no: dc.voucher_no,
                    customer_name:dc.customer_name,
                    customer_address_1:dc.customer_address_1,
                    customer_address_2:dc.customer_address_2,
                    customer_address_3:dc.customer_address_3,
                    customer_pincode:dc.customer_pincode,
                    consignee_name:dc.consignee_name,
                    consignee_address_1:dc.consignee_address_1,
                    consignee_address_2:dc.consignee_address_2,
                    consignee_address_3:dc.consignee_address_3,
                    consignee_pincode:dc.consignee_pincode,
                    purchase_order_no:dc.voucher_ref_no,
                    customer_gst_no:dc.customer_gst_no,
                    customer_pan_no:dc.pan_no,
                    user_remarks:dc.narration,
                    sales_order_no:dc.order_no,
                    sales_order_date:dc.order_date,
                    pay_terms:dc.terms_of_payment,
                    vehicle_no: dc.vehicle_no,
                    delivery_mode: dc.terms_of_delivery,
                    project_name:dc.destination,
                    dc_grade:  dc.description_of_goods,
                    dc_qty: dc.quantity,
                    basic_rate: dc.rate,
                    amount:  dc.value,
                    gross_total: dc.gross_total,
                    rounded_off: dc.rounded_off,
                    voucher_type:dc.voucher_type,
                    // Dynamically add tax properties using bracket notation
        ...response.tax_list.reduce((acc, tax) => {
            console.log(tax+"tax api");
            if(tax.includes(".")){
                console.log("if")
                acc[tax.replace(/[@.]/g, '_')] = (dc[tax] || 0); // If tax key does not exist, default to 0
            }
            else{
                console.log("else")
                acc[tax] = (dc[tax] || 0); // If tax key does not exist, default to 0
            }
            acc[tax] = (dc[tax] || 0); // If tax key does not exist, default to 0
            return acc;
        }, {})
                }
                    // response.tax_list.forEach(tax => {
                    //     rowData[tax] = dc[tax] || 0; // If tax key does not exist, default to 0
                    // });
                    // console.log(JSON.stringify(dc.tax_list)+"dc.taxlist");
                    
                    return rowData;
                });
                setTaxlist(response.tax_list);
                setData({
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error+"error"); 
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("plant,from_date,upto_date must not be empty.", "", "info");  
        }
    }

    return (
        <>
            <form className="form" onSubmit={handleSubmit}>
                <div className="container">
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                                     
                                <label htmlFor="plant_id" className="form-group col-sm-3 ">  </label>
                                <select id="plant_id" name="plant_id"  value={input.plant_id || ""} onChange={handleChange} className="form-control col-sm-4  browser-default custom-select  mandatory-form-control"  required >
                                <option value="">Select plant</option>
                                
                                    {plants.map((item) => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}</option>
                                    ))}
                                </select>&nbsp;&nbsp;
                                    <RangePicker
                                        defaultValue={[initialFromDate, initialToDate]}
                                        format="DD-MM-YYYY"
                                        onChange={handleDateChange}
                                        style={{height:"40px",width:"265px"}}
                                    />&nbsp;&nbsp;
                                        
                                    <Button  type="submit" style={{backgroundColor: "RGB(188,232,253)",fontWeight:"bold",height:"30px",display: "flex",
                                        alignItems: "center",  
                                        justifyContent: "center"}} >
                                        <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                    </Button> &nbsp;&nbsp;   
                                    <Button color="primary" onClick={handleDownloadCsv} startIcon={<FileDownloadRoundedIcon/>}  variant="contained">CSV</Button> 
                                        
                            </div>
                        </Col>
                    </Row> 
                </div>
            </form>
            <div className="container item-list-table-container">
                <div className="table-responsive">
                    <ReactTableWrapper
                        title='CustomerData Report'
                        columns={columns}
                        data={data.records}
                        onCSVExport={handleCSVExport}
                        // onPDFExport={handlePDFExport}
                        enableExportToolbar={false}
                        isLoading={isLoading}
                    />
                </div>
            </div> 
        </>
);
}
export default DcForTally;
